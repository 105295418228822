
























import { Component, Vue } from 'vue-property-decorator';

@Component
export default class NavBar extends Vue {
  navs = [
    {
      id: 0,
      route: '/blog',
      text: 'Blog',
    },
    {
      id: 1,
      route: '/me',
      text: 'Me',
    },
  ];

  links = [
    {
      name: 'github',
      target: '_blank',
      link: 'https://github.com/Jjungs7',
      icon: ['fab', 'github'],
    },
    {
      name: 'instagram',
      target: '_blank',
      link: 'https://www.instagram.com/_jjungs',
      icon: ['fab', 'instagram'],
    },
    {
      name: 'mail',
      target: '',
      link: 'mailto:jeongy95@gmail.com',
      icon: ['far', 'envelope'],
    },
  ];

  isAdmin() {
    const accessToken = localStorage.getItem('auth.accessToken');
    const expiresAt = localStorage.getItem('auth.expiresAt');

    return accessToken && expiresAt && parseInt(expiresAt, 10) > (new Date()).getTime();
  }
}
